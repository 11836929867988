import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="container d-flex flex-column justify-content-center" >
          <div className="row">
            <div className="col-12 col-md-6 mx-auto text-left">
              <h1>Francisco Javier Buendía</h1>
              <span>Estamos actualizando el contenido de la página...</span>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
